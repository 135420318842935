@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mulish", sans-serif;
}

.navbar {
  background-color: #ffffff !important;
}

.navbar-brand {
  font-size: 1.9rem !important;
  color: #565387 !important;
  font-family: "Dancing Script", cursive;
}

.navbar a {
  font-size: 1.3 rem;
  text-transform: capitalize;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #565387;
}

.navbar a:hover {
  color: #3498db !important;
}

@media (max-width: 991px) {
  .navbar-brand {
    font-size: 1.5rem !important;
  }

  .menu_active {
    font-weight: bold;
    border-bottom: none;
  }
}

/* ===================================================================================================
        Main Landing Page
   ===================================================================================================     
*/

#header {
  width: 100%;
  height: 77vh;
  margin-top: 28px;
}

/* footer {
  margin-top: 10px;
} */

#header h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  color: black;
}

#header .brand-name {
  color: #3498db;
}

#header h2 {
  color: #484848;
  font-size: 24px;
}

#header .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #3498db;
  border: 2px solid #3498db;
  text-decoration: none;
}

#header .btn-get-started:hover {
  background: #3498db;
  color: #ffffff;
}

.header-img {
  text-align: right;
}

/* #header .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
} */

/* @-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
} */

/* ===================================================================================================
        About Page
   ===================================================================================================     
*/

/* ===================================================================================================
        Experience Page
   ===================================================================================================     
*/

/* .my-card {
  background-color: #ffffff;
} */

/* ===================================================================================================
        Skills Page
   ===================================================================================================     
*/

.table {
  margin: auto;
  width: 100% !important;
}
table td {
  text-align: center;
}

.lang {
  color: #1a1920;
}

.lang:hover {
  color: #3498db;
}

/* ===================================================================================================
        Contact Page
   ===================================================================================================     
*/

/* ===================================================================================================
        Media queries
   ===================================================================================================     
*/

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  /* .table {
    margin: auto;
    width: 100%;
  }
  table td {
    text-align: center;
  } */
}
@media (max-width: 770px) {
  footer {
    margin-top: 129px;
    padding: 4px;
  }
}
@media (max-width: 400px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
